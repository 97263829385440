//
// -- Start editing -- //
//

@import "sass-flex-mixin/flexbox";

// Set the number of columns you want to use on your layout.
$grid-columns: 12;
// Set the gutter between columns.
$gutter-width: 1rem;
// Set a margin for the container sides.
$outer-margin: 2rem;
// Create or remove breakpoints for your project
// Syntax:
// name SIZErem,
$breakpoints:
  sm 48em 46rem,
  md 62em 61rem
  lg 75em 71rem;

//
// -- Stop editing -- //
//

.wrapper {
  box-sizing: border-box;
  max-width: 1200px;
  margin: 0 auto;
}

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-right: $outer-margin;
  padding-left: $outer-margin;
}

.row {
  box-sizing: border-box;
  @include flexbox();
  @include flex(0, 1, auto);
  @include flex-direction(row);
  @include flex-wrap(wrap);
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

.row.reverse {
  @include flex-direction(row-reverse);
}

.col.reverse {
  @include flex-direction(column-reverse);
}

$name: xs;
[class*="col-#{$name}"] {
  box-sizing: border-box;
  @include flex(0, 0, auto);
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
@for $i from 1 through $grid-columns {
  .col-#{$name}-#{$i} {
    @include flex-basis(100% / $grid-columns * $i);
    max-width: 100% / $grid-columns * $i;
  }
}
@for $i from 1 through $grid-columns {
  .col-#{$name}-offset-#{$i} {
    margin-left: 100% / $grid-columns * $i
  }
}
.col-#{$name} {
  @include flex-grow(1);
  @include flex-basis(0);
  max-width: 100%;
}
.start-#{$name} {
  @include justify-content(flex-start);
  text-align: start;
}

.center-#{$name} {
  @include justify-content(center);
  text-align: center;
}

.end-#{$name} {
  @include justify-content(flex-end);
  text-align: end;
}

.top-#{$name} {
  @include align-items(flex-start);
}

.middle-#{$name} {
  @include align-items(center);
}

.bottom-#{$name} {
  @include align-items(flex-end);
}

.around-#{$name} {
  @include justify-content(space-around);
}

.between-#{$name} {
  @include justify-content(space-between);
}

.first-#{$name} {
  order: -1;
}

.last-#{$name} {
  order: 1;
}


@each $breakpoint in $breakpoints {
  $name: nth($breakpoint, 1);
  $size: nth($breakpoint, 2);
  $container: nth($breakpoint, 3);
  @media only screen and (min-width: $size) {
    .container {
      width: $container;
    }

    [class*="col-#{$name}"] {
      box-sizing: border-box;
      @include flex(0, 0, auto);
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }
    @for $i from 1 through $grid-columns {
      .col-#{$name}-#{$i} {
        @include flex-basis(100% / $grid-columns * $i);
        max-width: 100% / $grid-columns * $i;
      }
    }
    @for $i from 1 through $grid-columns {
      .col-#{$name}-offset-#{$i} {
        margin-left: 100% / $grid-columns * $i
      }
    }
    .col-#{$name} {
      @include flex-grow(1);
      @include flex-basis(0);
      max-width: 100%;
    }
    .start-#{$name} {
      @include justify-content(flex-start);
      text-align: start;
    }

    .center-#{$name} {
      @include justify-content(center);
      text-align: center;
    }

    .end-#{$name} {
      @include justify-content(flex-end);
      text-align: end;
    }

    .top-#{$name} {
      @include align-items(flex-start);
    }

    .middle-#{$name} {
      @include align-items(center);
    }

    .bottom-#{$name} {
      @include align-items(flex-end);
    }

    .around-#{$name} {
      @include justify-content(space-around);
    }

    .between-#{$name} {
      @include justify-content(space-between);
    }

    .first-#{$name} {
      order: -1;
    }

    .last-#{$name} {
      order: 1;
    }
  }
}
