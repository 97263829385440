@import "normalize.scss";
@import "boilerplate.scss";
@import "flexboxgrid.scss";

html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
	font-family: Montserrat;
}

h1 {
	font-family: Archivo;
	text-transform: uppercase;
	letter-spacing: 0.3em;
}

h1 {
  font-size: 2em;
  /* 2x body copy size = 32px */
  line-height: 1.25;
  /* 45px / 36px */
}

ul {
  list-style-type: square;

  li {
  }
}

img {
  max-width: 100%;
}

a {
  color: inherit;
}

.svg {
  transition: filter 1s ease;
  path {
    transition: fill 1s ease;
    fill: transparent;   
    animation-fill-mode: forwards; 
  }  
}

.ratioWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;

  .ratiod {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

}

.tooltip {
  position: relative;
  border-bottom: 1px solid #eee;
  cursor:help;
  // &:before,
  // &:after {
  //   display: block;
  //   opacity: 0;
  //   pointer-events: none;
  //   position: absolute;
  //   transform: translate3d(0,0,0);
  //   transition: all .15s ease-in-out;    
  // }
  // &:after {
  //   border-right: 6px solid transparent;
  //   border-top: 6px solid rgba(0,0,0,.75); 
  //   border-left: 6px solid transparent;
  //   content: '';
  //   height: 0;
  //     top: -5px;
  //     left: 50%;
  //   width: 0;
  // }
  // &:before {
  //   background: rgba(0,0,0,.75);
  //   border-radius: 2px;
  //   color: #fff;
  //   content: attr(data-title);
  //   font-size: 14px;
  //   padding: 6px 10px;
  //   bottom: calc(100% + 5px);
  //   left: -50%;
  //   white-space: nowrap;
  // }

  // &:hover{
  //   &:after,
  //   &:before {
  //     opacity: 1;
  //     transform: translate3d(0,0,0);
  //   }
  // }
}

.text-right {
  text-align: right;
}

.wrapper {
  padding-bottom: 3em;
}

.animated {

  .svg {
    // filter: grayscale(100%);
    // &:hover {
    //   filter: grayscale(0); 
    // };    
  }

  .svg-group {
    transition: stroke 0.1s ease;    
    stroke: transparent;
    animation-fill-mode: forwards;
  }

  #dizzeeRascal { 
    path{fill:black;}
  }

  #sonyATV {
    path{fill:black;}    
  }

  #emi {
    .st0{fill:#CF001A;}
    .st1{fill:#FFFFFF;}
  }

  #universalMusicGroup {
    .st0{fill:#231F20;}
    .st1{fill-rule:evenodd;clip-rule:evenodd;fill:#231F20;}    
  }

  #jaguarCars {
    path{fill:black;}    
  }

  #netflix {
    .st0{fill:#D81F26;}
  }

  #adidas { 
    path{fill:black;}
  }

 #heineken {
    .st0{fill:#24541F;}
    .st1{fill:#FA3000;}    
  }

  #specsavers{
    .st0{fill:#009552;}
    .st1{fill:#00693C;}
    .st2{fill:#FFFFFF;}
  }

  #travisPerkins {
    .st0{fill:#1C4331;}
    .st1{fill:#F6A62E;} 
  }

  #rushHair {
    .st0{fill:#231F20;}
  }

  #youtubeMusic {
    .st0{fill:#FF0000;}
    .st1{fill:#FFFFFF;}
  }
}

.avatar-container {
  text-align: center;
 
}

.avatar-img {
  border-radius: 100%;
  width: 100px;
}

/* Style the container with a rounded border, grey background and some padding and margin */
.testimonial {
  margin: 1em 0;
  text-align: center;
}

.recent-img {
  margin: 1em 0 2em;
}

.contact-form {
  input[type="email"] {
    border: 1px solid grey;
    border-radius: 5px;
    display: block;
    width: 100%;
    padding: 10px; 
  }

  textarea {
    border: 1px solid grey;
    border-radius: 5px;
    display: block;
    width: 100%;
    margin: 1em 0;
    padding: 10px; 
    height: 150px;
    resize: none;

  }

  button[type="submit"] {
    border: 1px solid grey;
    border-radius: 5px;
    font-weight: bold;
    padding: 10px;
  }
}

@media (min-width: 43.75em) {
  h1 {
    font-size: 2.5em;
    /* 2.5x body copy size = 40px */
    line-height: 1.125;
  }

  .testimonial {
    text-align: left;  
  }  
}

@media (min-width: 56.25em) {
  h1 {
    font-size: 3em;
    /* 3x body copy size = 48px */
    line-height: 1.05;
    /* keep to a multiple of the 20px line height 
    and something more appropriate for display headings */
  }
}

.u-break {
	display: block;
}

.u-uppercase {
	text-transform: uppercase;
}

.u-colorGrey {
	color: #666;
}

.u-textRight {
  text-align: right;
}